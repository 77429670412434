@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./scss/variables";
@import "./scss/animation";
@import "@fontsource/inter/100.css";
@import "@fontsource/inter/200.css";
@import "@fontsource/inter/300.css";
@import "@fontsource/inter/400.css";
@import "@fontsource/inter/500.css";
@import "@fontsource/inter/600.css";
@import "@fontsource/inter/700.css";
@import "@fontsource/inter/800.css";
@import "@fontsource/inter/900.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  background: black;
  transition: background-color 0.5s, color 0.5s border-color 0.5s;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

html {
  height: fit-content;
}

#root {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

img {
  width: 100%;
  max-width: fit-content;
  height: 100%;
}

#root_page {
  position: relative;
  overflow-x: auto;
  min-height: 100vh;
  .Toastify__toast-theme--light {
    border-radius: 12px;
    box-shadow: 0 1px 10px 0 rgb(239 78 174 / 44%),
      0 2px 15px 0 rgb(239 78 174 / 14%);
    border: 1px solid var(--pink2-color);
  }
  .Toastify__toast-body {
    padding: 0;
  }
}

input[type="number"] {
  &::-webkit-inner-spin-button,
  &::-webkit-outner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.form-group {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  &.form-wrap-btn {
    margin-bottom: 0;
  }
  .form-label {
    font-size: 16px;
    margin-bottom: 12px;
  }
  .form-input {
    width: 100%;
    padding: 8px 10px;
    outline: none;
    border-radius: 8px;
    border: 1px solid transparent;
  }
}

html body {
  .rs-input-group,
  .rs-input-group:focus-within {
    background: transparent !important;
    height: 40px;
    color: inherit !important;
    border: none !important;
    .rs-input-group-addon {
      color: inherit !important;
    }
    &:focus-within {
      border: none !important;
      outline: 0 !important;
    }
    .rs-input {
      background: transparent !important;
      outline: none !important;
      border-color: transparent !important;
      padding: 0;
      font-size: 14px;
      color: inherit !important;
      &::placeholder {
        font-size: 14px;
      }
      &:hover {
        border-color: transparent !important;
      }
    }
  }
  .rs-picker-popup {
    .rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
      background: #12b76a;
    }
    .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
      box-shadow: inset 0 0 0 1px #12b76a;
    }
    .rs-calendar-table-cell {
      &:hover {
        .rs-calendar-table-cell-content {
          background: #ecfdf3 !important;
          color: #12b76a !important;
        }
      }
    }
    .rs-calendar-time-dropdown-cell {
      &.rs-calendar-time-dropdown-cell-active {
        background-color: #12b76a;
      }
      &:hover {
        background: #ecfdf3;
        color: #12b76a;
      }
    }
    .rs-btn-primary {
      background: #12b76a;
      &:hover {
        background: #039855;
      }
    }
    .rs-calendar-time-dropdown-column > ul {
      padding-bottom: 16px !important;
    }
  }
  .rs-dark-calendar-popup-date {
    background: #292929 !important;
    color: white !important;
    .rs-calendar-table-cell {
      &:hover {
        .rs-calendar-table-cell-content {
          background: #054f31 !important;
          color: #d1fadf !important;
        }
      }
    }

    .rs-calendar-time-dropdown-column-title {
      background-color: black !important;
    }
    .rs-calendar-time-dropdown,
    .rs-calendar-btn-close {
      background: #1e1e1e;
      color: white;
    }
    .rs-calendar-time-dropdown-cell {
      &:hover {
        background: #054f31;
        color: #d1fadf;
      }
    }
    .rs-btn-xs {
      color: white;
      &:hover,
      &:focus {
        background: #373737;
        color: white;
      }
    }
    .rs-picker-toolbar {
      border-top-color: #353535;
      padding: 8px 12px;
    }
  }
}

.btn-primary {
  background: $color-primary;
  color: white;
  &:hover {
    background: $color-primary-hover;
  }
}

.btn-secondary {
  background: $color-background3;
  color: $color-link;
  &:hover {
    opacity: 0.8;
  }
}

.btn-linear {
  background: linear-gradient(99deg, #ff5959 0%, #ffaa47 100.69%);
  &:hover {
    background: linear-gradient(99deg, #f53838 0%, #f69b32 100.69%);
  }
}

.word-break {
  word-break: break-word;
}

.rotate-y-0 {
  transform: rotateY(0) !important;
}

.rotate-z-180 {
  transform: rotateZ(180deg);
}

.-rotate-z-180 {
  transform: rotateZ(-180deg);
}

.rotate-z-90 {
  transform: rotateZ(90deg);
}

.-rotate-z-90 {
  transform: rotateZ(-90deg);
}

.rotate-y-180 {
  transform: rotateY(180deg);
}

.-rotate-y-180 {
  transform: rotateY(-180deg);
}

.rotate-y-90 {
  transform: rotateY(90deg);
}

.-rotate-y-90 {
  transform: rotateY(-90deg);
}

.backface-hidden {
  backface-visibility: hidden;
}

.perspective-1000 {
  perspective: 1000px;
}

.style-preserve-3d {
  transform-style: preserve-3d;
}

.child-group:hover .child-group-hover\:scale-110 {
  transform: scale(1.1);
}

button {
  &.disable {
    opacity: 0.4;
    pointer-events: none;
  }
}

input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: black;
    caret-color: black;
  }
}

.dark input {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: white;
    caret-color: white;
  }
}

.shadow-table {
  box-shadow: 0px 1px 2px 0px #1018280f;
  box-shadow: 0px 1px 4px 0px #10182833;
}

.bg-linear-gradient {
  background: linear-gradient(90deg, #32d583 0%, #1a6f45 100%);
}

.bg-ball-active {
  box-shadow: 0px 4px 4px 0px #ffffff66 inset;
  background: #22d01e;
}

.bg-linear {
  background: linear-gradient(
    97deg,
    rgba(60, 58, 51, 0) 1.38%,
    #513f00 130.82%
  );
}

.grid-cols-19 {
  grid-template-columns: repeat(19, minmax(0, 1fr));
}

.grid-cols-24 {
  grid-template-columns: repeat(24, minmax(0, 1fr));
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-radius: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #12b76a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #039855;
}

.text-animation-up {
  animation: scaleUp 1s ease-in-out infinite;
}

.text-animation-down {
  animation: scaleDown 1s ease-in-out infinite;
}

.animation-add-chip {
  animation: taixiuAddChip 0.5s forwards;
}

.animation-loader-outter {
  animation: loader-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

.animation-loader-inner {
  animation: loader-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
}

.animation-effect-result {
  animation: effectResult 0.8s linear infinite;
}

.animation-effect-scale {
  animation: scaleEffect 0.5s linear 20;
}

@layer utilities {
  .clip-custom {
    clip-path: polygon(0% 0%, 80% 0%, 100% 100%, 0% 100%);
  }
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari, and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge, and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
}

.content-noti {
  &.dark {
    table {
      th,
      td {
        padding: 8px;
        text-align: left;
        border: 1px solid #353535;
      }

      th {
        background-color: black; /* Màu nền của header */
      }

      tr:nth-child(odd) {
        background-color: black; /* Màu nền của hàng chẵn */
      }
    }
  }
  a {
    color: #12b76a;
    text-decoration: underline;
    &:hover {
      color: #039855;
    }
  }
  ol,
  ul {
    padding-left: 20px;
    list-style: inherit !important;
  }
  table {
    border-collapse: collapse;
    width: 100%;
    th,
    td {
      padding: 8px;
      text-align: left;
      border: 1px solid #d5d5d5;
    }

    th {
      background-color: #f2f2f2; /* Màu nền của header */
    }

    tr:nth-child(odd) {
      background-color: #f2f2f2; /* Màu nền của hàng chẵn */
    }
  }
}

.hover-line {
  @apply relative;
}
.hover-line::after {
  content: "";
  @apply absolute w-0 h-0.5 bg-primary -bottom-1 left-0 transition-[width] duration-300 ease-in-out;
}
.hover-line:hover::after {
  @apply w-full text-white;
}

@keyframes border-light {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 200% 0%;
  }
}

.border-light-hover {
  background: #177c3c;
  position: relative;
  overflow: hidden;
  &::before {
    content: "";
    top: 0;
    left: 0;
    position: absolute;
    width: 150%;
    height: 150%;
    border-radius: 100px;
    background-image: conic-gradient(
      transparent,
      transparent,
      transparent,
      white
    );
    animation: loader-outter 2s linear infinite;
    z-index: 0;
  }
  &::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 10px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: #171717;
    z-index: 1;
  }
}

.text-linear-primary {
  background: linear-gradient(90.86deg, #ffffff 50.9%, #52db85 99.73%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.text-linear-primary-dark {
  background: linear-gradient(90.86deg, #000 50.9%, #52db85 99.73%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
}

.animate-diagonal-grow {
  animation: diagonalGrow 1s ease-out forwards;
}

.bg-shadow {
  background: linear-gradient(0deg, #0e1013 25.55%, rgba(19, 19, 19, 0) 93.45%);
}

.bg-shadow-light {
  background: linear-gradient(
    0deg,
    #f0fdf4 23.38%,
    rgba(240, 253, 244, 0) 93.45%
  );
}

// loading
.floatingCirclesG {
  position: relative;
  width: 22px;
  height: 22px;
  margin: auto;
  transform: scale(0.6);
  -o-transform: scale(0.6);
  -ms-transform: scale(0.6);
  -webkit-transform: scale(0.6);
  -moz-transform: scale(0.6);
}
.floatingCirclesG.hide {
  display: none;
}
.floatingCirclesG .f_circleG {
  position: absolute;
  background-color: #bfb3ff;
  height: 4px;
  width: 4px;
  border-radius: 2px;
  -o-border-radius: 2px;
  -ms-border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  animation-name: f_fadeG;
  -o-animation-name: f_fadeG;
  -ms-animation-name: f_fadeG;
  -webkit-animation-name: f_fadeG;
  -moz-animation-name: f_fadeG;
  animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  -ms-animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
  -moz-animation-duration: 1.2s;
  animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  -ms-animation-iteration-count: infinite;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-direction: normal;
  -o-animation-direction: normal;
  -ms-animation-direction: normal;
  -webkit-animation-direction: normal;
  -moz-animation-direction: normal;
}
.floatingCirclesG .frotateG_01 {
  left: 0;
  top: 9px;
  animation-delay: 0.45s;
  -o-animation-delay: 0.45s;
  -ms-animation-delay: 0.45s;
  -webkit-animation-delay: 0.45s;
  -moz-animation-delay: 0.45s;
}
.floatingCirclesG .frotateG_02 {
  left: 3px;
  top: 3px;
  animation-delay: 0.6s;
  -o-animation-delay: 0.6s;
  -ms-animation-delay: 0.6s;
  -webkit-animation-delay: 0.6s;
  -moz-animation-delay: 0.6s;
}
.floatingCirclesG .frotateG_03 {
  left: 9px;
  top: 0;
  animation-delay: 0.75s;
  -o-animation-delay: 0.75s;
  -ms-animation-delay: 0.75s;
  -webkit-animation-delay: 0.75s;
  -moz-animation-delay: 0.75s;
}
.floatingCirclesG .frotateG_04 {
  right: 3px;
  top: 3px;
  animation-delay: 0.9s;
  -o-animation-delay: 0.9s;
  -ms-animation-delay: 0.9s;
  -webkit-animation-delay: 0.9s;
  -moz-animation-delay: 0.9s;
}
.floatingCirclesG .frotateG_05 {
  right: 0;
  top: 9px;
  animation-delay: 1.05s;
  -o-animation-delay: 1.05s;
  -ms-animation-delay: 1.05s;
  -webkit-animation-delay: 1.05s;
  -moz-animation-delay: 1.05s;
}
.floatingCirclesG .frotateG_06 {
  right: 3px;
  bottom: 3px;
  animation-delay: 1.2s;
  -o-animation-delay: 1.2s;
  -ms-animation-delay: 1.2s;
  -webkit-animation-delay: 1.2s;
  -moz-animation-delay: 1.2s;
}
.floatingCirclesG .frotateG_07 {
  left: 9px;
  bottom: 0;
  animation-delay: 1.35s;
  -o-animation-delay: 1.35s;
  -ms-animation-delay: 1.35s;
  -webkit-animation-delay: 1.35s;
  -moz-animation-delay: 1.35s;
}
.floatingCirclesG .frotateG_08 {
  left: 3px;
  bottom: 3px;
  animation-delay: 1.5s;
  -o-animation-delay: 1.5s;
  -ms-animation-delay: 1.5s;
  -webkit-animation-delay: 1.5s;
  -moz-animation-delay: 1.5s;
}

@keyframes f_fadeG {
  0% {
    background-color: #12b76a;
  }
  100% {
    background-color: #fff;
  }
}
@-webkit-keyframes f_fadeG {
  0% {
    background-color: #12b76a;
  }
  100% {
    background-color: #fff;
  }
}

html .MuiBackdrop-root.MuiModal-backdrop {
  background: rgb(47 41 41 / 17%) !important;
}



.paginate {
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  // color: #ffffff4d;
  cursor: pointer;
  &:hover {
    border-color: #1fbd53 !important;
  }
}

.paginate-active {
  border: 1px solid #1fbd53 !important;
}
